import React from "react";
import Layout from "../components/Layout";

// @ts-ignore
import pic1 from '../assets/images/a01.jpg';
// @ts-ignore
import pic2 from '../assets/images/a02.jpg';
// @ts-ignore
import pic3 from '../assets/images/pic04.jpg';

const IndexPage = () => (
    <Layout>
        <article id="main">
            <header>
                <h2>...ABOUT US...</h2>
                <p>"The secret of getting successful is getting Started"</p>
            </header>
          
            <section className="wrapper style5">
                <div className="inner">
                      <h2>OVERVIEW</h2>
                    <p>
                        JR Global is a consulting and business process services company. We harness
                        the power of congnitive, computing, hyper-automation, cloud, analytics and emerging
                        technologies to help our clients adapt to the digital world and make them successful.
                        <br />
                    </p>
                    <p>
                        A federation of companies bound by one purpose - to Rise, Inspired by this spirit, our
                        legacy and values, or goal to always positively impact our partners, stakeholders, communities
                       and the world at large, remains unshakeable. 
                    </p>
                    <h2>OUR VALUES</h2>
                    <div>
                        <p><span className="image left"><img src={pic1} alt="Technology" /></span>
                            <strong>TRUST</strong><br />
                            Trust is the basis for everything we do and the prerequisite for our success.
                            We keep our promises. To all our employees and all stakeholders in JR Global
                            (such as customers, employees, investors, business partners, politicians and
                            society). We are aware that trust is hard to gain and can be easily destroyed.
                            That is why we prove at all times that the trust placed in us is worth it.
                        </p>
                    </div>

                    <div>
                        <p><span className="image right"><img src={pic2} alt="Globe" /></span>
                            <strong>PASSION TO WIN</strong><br />
                            Competition is our world; top performance is our goal. For we want to create value on a permanent basis.
                            “Quality First” is a core value at JR Global: Our services always meet the highest quality standards.
                            We overcome obstacles and boundaries. And we never let up in any of these endeavours.
                        </p>
                    </div>

                    <div>
                        <p><span className="image left"><img src={pic3} alt="Growth" /></span>
                            <strong>EXCELLENCE WITH INTEGRITY</strong><br />
                            We always strive for excellence and professionalism in everything we do and we always try to improve
                            our knowledge.<br />
                            We will always be straightforward in all of our dealings and serve our customers
                            with complete honesty no matter the consequence.
                        </p>
                    </div>
                </div>
            </section>
           

        </article>

    </Layout>
);

export default IndexPage